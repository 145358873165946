import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes,ExtraOptions } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'new-service',
    loadChildren: () => import('./pages/services/new-service/new-service.module').then( m => m.NewServicePageModule)
  },
  {
    path: 'my-service',
    loadChildren: () => import('./pages/services/my-service/my-service.module').then( m => m.MyServicePageModule)
  },
  {
    path: 'service-details',
    loadChildren: () => import('./pages/services/service-details/service-details.module').then( m => m.ServiceDetailsPageModule)
  },
  {
    path: 'SettingPage',
    loadChildren: () => import('./pages/setting/setting.module').then( m => m.SettingPageModule)
  },
  {
    path: 'work-order',
    loadChildren: () => import('./pages/workOrder/work-order/work-order.module').then( m => m.WorkOrderPageModule)
  },
  {
    path: 'WorkOrderDashboardPage',
    loadChildren: () => import('./pages/workOrder/work-order-dashboard/work-order-dashboard.module').then( m => m.WorkOrderDashboardPageModule)
  },
  {
    path: 'work-order-list',
    loadChildren: () => import('./pages/workOrder/work-order-list/work-order-list.module').then( m => m.WorkOrderListPageModule)
  },
  {
    path: 'NotificationPage',
    loadChildren: () => import('./pages/notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'ProfilePage',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  }
];
const config: ExtraOptions = {
  useHash: true,
  onSameUrlNavigation: 'reload',
  preloadingStrategy: PreloadAllModules
};
@NgModule({
  imports: [
    RouterModule.forRoot(routes, config)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
