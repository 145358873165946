import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule,HttpClient } from  '@angular/common/http';
import { RestServiceProvider } from 'src/providers/rest-service/rest-service';
import { SharedDataProvider } from 'src/providers/shared-data/shared-data';
import { config } from 'src/shared/config';
import { ProcessHttpmsgProvider } from 'src/providers/process-httpmsg/process-httpmsg';
import { TranslateModule, TranslateLoader, TranslateService, TranslateStore } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { AlertProvider } from 'src/providers/alert/alert';
import { ToastProvider } from 'src/providers/toast/toast';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    IonicModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }), 
  ],
  providers: [
    RestServiceProvider,
    SharedDataProvider,
    ProcessHttpmsgProvider,
    TranslateService,
    TranslateStore ,
    AlertProvider,
    ToastProvider,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: 'config', useValue: config},
    Network,
    PhotoViewer,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
