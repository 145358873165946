import { Component } from '@angular/core';
import { NavController, Platform, MenuController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SharedDataProvider } from 'src/providers/shared-data/shared-data';
import { RestServiceProvider } from 'src/providers/rest-service/rest-service';
import { config } from 'src/shared/config';
import { Router } from '@angular/router';
import { AlertProvider } from 'src/providers/alert/alert';
import { Network } from '@awesome-cordova-plugins/network/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  loaderShow: boolean = false;
  constructor(public platform: Platform,
    public translate: TranslateService,
              public navCtrl: NavController,
              public restServiceProvider : RestServiceProvider,
              public sharedDataProvider : SharedDataProvider,
              private router: Router,
              private alertProvider : AlertProvider,
              private network: Network,
              private menuCtrl: MenuController
   ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
   //   this.statusBar.backgroundColorByHexString('#e43f03');  orange
   //this.statusBar.backgroundColorByHexString('#36b557');  //green
    //  this.splashScreen.hide();
   this.initApp();
   this.defaultLang();
    });
  }
initApp(){
  if(localStorage.getItem("isLogin") == "Yes" && localStorage.getItem("isLogout") == "No"){
    this.sharedDataProvider.getStorage();
    this.menuCtrl.enable(true);
    this.navCtrl.navigateRoot('dashboard');
    }else{
      this.navCtrl.navigateRoot('login');
    }
}
defaultLang(){
  var lang = navigator.language;
  if(lang){
    var userLang : any = navigator.language.split('-');
    if(localStorage.getItem("lang")){
      userLang = localStorage.getItem("lang");
      this.setLanguage(userLang);
    }
    else{
      if(userLang && userLang.length > 0){
        this.setLanguage(userLang[0]);
      }else{
        this.setLanguage("en");
      }
     }
  }
}
  setLanguage(userLang) {
    localStorage.setItem("lang", userLang);
    userLang = /(en|de|fr|nl)/gi.test(userLang) ? userLang : 'en';
    this.translate.use(userLang);
    this.sharedDataProvider.setTsJSON();
}

branchList(){
    this.loaderShow = true;
    this.restServiceProvider.getService(config['BranchList']).subscribe(data => {
      this.loaderShow = false;
      localStorage.setItem("branchList", JSON.stringify(data))
    },
    (error) => {
      this.loaderShow = false;
      if(error.status == 401){
        this.sessionTimeout();
      }
    })
}

openPage(page) {
  this.menuCtrl.toggle();
  if(page.title == 'Afmelden' || page.title == 'Sign out' || page.title == 'Abmelden' || page.title == 'DÉCONNEXION'){
    this.logoutFunction();
  }
  else if(page.title =="Home"){
    this.navCtrl.navigateForward(page.component);
  }
  else{
    this.navCtrl.navigateForward(page.component);
  }
}


logoutFunction(){
  if(this.network.type === 'none'){
    this.alertProvider.showWithTitle('Geen internet verbinding', 'Verbind het internet om te beginnen.');
  }
  else{
    this.loaderShow = true;
    let postJson = {
      "PlayerId" : localStorage.getItem("notificationId"),
      "Flag" : "SignOut"
    }
    this.restServiceProvider.postService(config['PlayerIdAssociation'], postJson).subscribe(data => {
    localStorage.removeItem("isLogin");
    this.navCtrl.navigateRoot('login');
      this.loaderShow = false;
    },
    (error) => {
      this.loaderShow = false;
      localStorage.removeItem("isLogin");
      this.navCtrl.navigateRoot('login');
      this.sharedDataProvider.userData = {};
      localStorage.clear();
    })
}
}

sessionTimeout(){
  this.logoutFunction();
}
}
