import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import  'rxjs/add/operator/catch';
import  'rxjs/add/operator/map';
import {config} from '../../shared/config';
import { ProcessHttpmsgProvider } from '../process-httpmsg/process-httpmsg';
import 'rxjs/Rx';

@Injectable()
export class RestServiceProvider {
 public http: any;
 public data: any;
 public baseUrl: String;  
 public baseAPI_Url: String;
 public headers: any;
 public options: any;
 public storageData: JSON;
  constructor(http: HttpClient, 
              private processHttpmsgService : ProcessHttpmsgProvider,
              ) {
                this.http = http;
                this.storageData = JSON.parse('{}');
                this.baseUrl = config['baseURL'];
                this.baseAPI_Url = config['baseURL']
                this.initializeHeaders();

}

initializeHeaders() {
 this.headers = new HttpHeaders({'Content-Type': 'application/json'});
if(localStorage.getItem("Token")){
  this.headers = this.headers.append('Authorization', localStorage.getItem("Token"));
  this.headers = this.headers.append('accept-language', localStorage.getItem("lang"))
}
  this.options = { headers: this.headers };
}

getService(action: string) {
    this.initializeHeaders();
  return  this.http.get(this.baseAPI_Url + action, this.options)
  .map(res => { return this.processHttpmsgService.extractData(res) })
  .catch(error => { return this.processHttpmsgService.handleError(error) });
}

postService(action: string, body: any) {
    this.initializeHeaders();
    
        return  this.http.post(this.baseAPI_Url + action, body, this.options)
        .map(res => { return this.processHttpmsgService.extractData(res) })
        .catch(error => { return this.processHttpmsgService.handleError(error) });
}
}
