export const config = {
  //Development
   //  baseURL                       : 'https://dbsnl.azurewebsites.net/',
    
    ///  baseURL                  : 'http://192.168.137.1/',
  //Live Prod
   baseURL                 : 'https://profile-dbs.azurewebsites.net/', 
    isProd                  : 'Yes',
    //baseURL  :  'https://profile-dbs-test.azurewebsites.net/',
    token                   : 'token',
    Register                : 'api/Account/Register',
    UserInfo                : 'api/Account/UserInfo',
    ChangePassword          : 'api/Account/ChangePassword',
    BranchList              : 'api/Claim/BranchList',
    ClaimList               : 'api/Claim/ClaimList',
    CreateClaim             : 'api/Claim/CreateClaim',
    getClaimImages          : 'api/Claim/getClaimImages',
    ClaimUploadImagesModel  : 'api/Claim/ClaimUploadImagesModel',
    ClaimDeleteImage        : 'api/Claim/ClaimDeleteImage',
    PlayerIdAssociation     : 'api/Account/PlayerIdAssociation',
    NotificationList        : 'Api/Notification/NotificationList',
    DashSlider              : 'api/ApplicationSettings/getClaimImages',
    WOList                  : 'Api/WorkOrder/WOList?SearchText=',
    WOMeasurementDetails    : 'api/WorkOrder/WOMeasurementDetails?WorkOrderId=',
    WOTyreDetails           : 'api/WorkOrder/WOTyreDetails?WorkOrderId=',
    WOUpdateCreate          : 'api/WorkOrder/',
    WOMeasurementUpdate     : 'Api/WorkOrder/WOMeasurementUpdate',
    WOTyreUpdate            : 'api/WorkOrder/WOTyreUpdate',
    WOdelete                : 'Api/WorkOrder/WOdelete',
    WorkOrderUploadImages   : 'api/WorkOrder/WorkOrderUploadImages',
    getWorkOrderImages      : 'api/WorkOrder/getWorkOrderImages',
    WorkOrderDeleteImage    : 'api/WorkOrder/WorkOrderDeleteImage',
    GetUserModuleAccess     : 'api/ModuleAccess/GetUserModuleAccess',
    getmechanicjoblist      : 'api/Mechanic/getmechanicjoblist',
    getmechanicjobdetails   : 'api/Mechanic/getmechanicjobdetails?RequestId=',
    UpdateMechanicLocation  : 'api/Mechanic/UpdateMechanicLocation',
    getDamageImages         : "api/CustomerServiceRequest/getDamageImages",
    MechanicAcceptRejectRequest  : 'api/Mechanic/MechanicAcceptRejectRequest',
    TranslateText           : 'api/CustomerServiceRequest/TranslateText'

}