import { Injectable } from '@angular/core';
import { HttpClient } from  '@angular/common/http';
import { Observable } from  'rxjs';
import  'rxjs/add/operator/catch';
import  'rxjs/add/operator/map';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';

@Injectable()
export class ProcessHttpmsgProvider {

  constructor(public http: HttpClient) {
    console.log('Hello ProcessHttpmsgProvider Provider');
  }
  public extractData(res: Response){
    if(res['_body'] != "" && res['_body'] != undefined){
      return res.json();
    }
    else{
      return res;
    }
  }

  public handleError(error: Response | any){
    let errMsg: any;
    if(error instanceof Response){
      const body = error.json();
     errMsg = body;
     
     if(errMsg['Message'] == "Authorization has been denied for this request."){
       localStorage.setItem("isLogout", "Yes");
     }
     else{
      localStorage.setItem("isLogout", "No");
     }
    }else if(error.status == 0 || error.status == 503 ){
      errMsg = error;
    }
    else if(error.status == 401 ){
      localStorage.setItem("isLogout", "Yes");
      errMsg = error;
    }
    else{
    //  errMsg = error.message ? error.message : error.toString();
      errMsg = error;
    }
    return Observable.throw(errMsg)
  }
}
