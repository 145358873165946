import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable()
export class AlertProvider {
  okText = "ok";
  alertText = "Alert";
  constructor(
    public alertCtrl: AlertController,
  ) {
    }
    async  show(text) {
    let alert = await  this.alertCtrl.create({
      header: this.alertText,
      subHeader: text,
      buttons: [this.okText]
    });
    alert.present();
  }

  async showWithTitle(text, title) {
    let alert = await this.alertCtrl.create({
      header: title,
      subHeader: text,
      buttons: [this.okText]
    });
      alert.present();
  }

  async noInternetConnection() {
    let alert =await this.alertCtrl.create({
      header: "Geen internet verbinding",
      subHeader: "Verbind het internet om te beginnen",
      buttons: [this.okText]
    });
    alert.present();
  }
}
