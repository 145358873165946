import { Injectable } from '@angular/core';
import { HttpClient } from  '@angular/common/http';
import { Camera, CameraResultType } from '@capacitor/camera';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';

import  'rxjs/add/operator/catch';
import  'rxjs/add/operator/map';
@Injectable()
export class SharedDataProvider {
  public tsJSON: any;
  public tsJSONSidemenu: any = [];
  public userData : any = {};
  public storageParms : any  = {}
  constructor(private  httpClient : HttpClient,
    private photoViewer: PhotoViewer) {
  }

  setTsJSON(){
    let userLang = localStorage.getItem('lang');
    this.httpClient.get('assets/i18n/' + userLang + '.json').subscribe(data => {
      this.tsJSON = data;
      this.tsJSONSidemenu = data['SIDEMENU'];
  });
}

setStorage(){
  localStorage.setItem('userData', JSON.stringify(this.userData));
  this.getStorage();
}
getStorage(){
  if(localStorage.getItem('userData')){
    this.userData = JSON.parse(localStorage.getItem('userData'));
  }
    
}

async  openCamera(){
//   const options: CameraOptions = {
//     quality: 70,
//     destinationType: this.camera.DestinationType.DATA_URL,
//     encodingType: this.camera.EncodingType.JPEG,
//     mediaType: this.camera.MediaType.PICTURE
//   }
// return  this.camera.getPicture(options).then((imageData) => {
//    console.log("shared",imageData)
//    let base64Image = imageData;
//    return base64Image;
//   }, (err) => {
//   });
const image = await Camera.getPhoto({
  quality: 60,
  allowEditing: false,
  resultType: CameraResultType.Base64
});
let imageUrl = image.base64String;
return imageUrl;
}

openImagePicker(){
//   const options: ImagePickerOptions = {
//     quality: 100,
//     maximumImagesCount: 1,
//     width: 450,
//     height: 270,
//     outputType: 1
//   }
// return  this.imagePicker.getPictures(options).then((results) => {
//    // return   this.cropImg(results[0]);
//    if(results == 'OK'){
  
//    }
//    else{
//     if(results.length > 0){
//       return   results[0];
//      }
//    }
//   }, (err) => { });
}

photoView(url){
  this.photoViewer.show(url, '', {share: false});
}
}
